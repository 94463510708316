import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const BlueMermaidFullwidth = () => {
  return (
    <section className="rivian-full-width py-0">        
        <Container fluid>
            <Row>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-1.jpg" alt="Blue Mermaid Events Brand Identity Presentation 1 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-2.jpg" alt="Blue Mermaid Events Brand Identity Presentation 2 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-3.jpg" alt="Blue Mermaid Events Brand Identity Presentation 3 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-4.jpg" alt="Blue Mermaid Events Brand Identity Presentation 4 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-5.jpg" alt="Blue Mermaid Events Brand Identity Presentation 5 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-6.jpg" alt="Blue Mermaid Events Brand Identity Presentation 6 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-7.jpg" alt="Blue Mermaid Events Brand Identity Presentation 7 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-8.jpg" alt="Blue Mermaid Events Brand Identity Presentation 8 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-9.jpg" alt="Blue Mermaid Events Brand Identity Presentation 9 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-10.jpg" alt="Blue Mermaid Events Brand Identity Presentation 10 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-11.jpg" alt="Blue Mermaid Events Brand Identity Presentation 11 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-12.jpg" alt="Blue Mermaid Events Brand Identity Presentation 12 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-13.jpg" alt="Blue Mermaid Events Brand Identity Presentation 13 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-14.jpg" alt="Blue Mermaid Events Brand Identity Presentation 14 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-15.jpg" alt="Blue Mermaid Events Brand Identity Presentation 15 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-16.jpg" alt="Blue Mermaid Events Brand Identity Presentation 16 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-17.jpg" alt="Blue Mermaid Events Brand Identity Presentation 17 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-18.jpg" alt="Blue Mermaid Events Brand Identity Presentation 18 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-19.jpg" alt="Blue Mermaid Events Brand Identity Presentation 19 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-20.jpg" alt="Blue Mermaid Events Brand Identity Presentation 20 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-21.jpg" alt="Blue Mermaid Events Brand Identity Presentation 21 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-22.jpg" alt="Blue Mermaid Events Brand Identity Presentation 22 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-23.jpg" alt="Blue Mermaid Events Brand Identity Presentation 23 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-24.jpg" alt="Blue Mermaid Events Brand Identity Presentation 24 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-25.jpg" alt="Blue Mermaid Events Brand Identity Presentation 25 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/blue-mermaid-events/blue-mermaid-events-branding-image-26.jpg" alt="Blue Mermaid Events Brand Identity Presentation 26 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>                                                                                                                                
            </Row>
        </Container>
    </section>
  )
}

export default BlueMermaidFullwidth