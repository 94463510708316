import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/branding/blue-mermaid-events.scss"

import brandingFeaturedImage from "../../../static/branding/blue-mermaid-events/blue-mermaid-events-featured-image.png"
import BlueMermaidMain from "../../components/branding/blue-mermaid-events/blueMermaidMain"
import BlueMermaidFullwidth from "../../components/branding/blue-mermaid-events/blueMermaidFullWidth"
import BlueMermaidMoreBranding from "../../components/branding/blue-mermaid-events/blueMermaidMoreBranding"
export const Head = () => (
    <>
        <SeoHead 
            ogImage={brandingFeaturedImage}
            title="Blue Mermaid Events: Versatile Event Branding | WowMakers"
            description="Explore our captivating brand design for Blue Mermaid Events. We crafted a versatile identity for their diverse event planning services."
        />
    </>
)

const BlueMermaidEventsBranding = () => {
    
    return (
        <Layout>
            <Seo
                bid="branding-blue-mermaid-events"
                bclass="case-studies-page branding"
            ></Seo>
            <ScrollIndicationHeightZero />
            <BlueMermaidMain />
            <BlueMermaidFullwidth />
            <BlueMermaidMoreBranding />
        </Layout>
    )
}

export default BlueMermaidEventsBranding
